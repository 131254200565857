.switch {
    position: relative;
    display: inline-block;
    width: 32px;
    height: 20px;

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $color-gray-dark;
        transition: .4s;
        border-radius: 30px;

        &:before {
            position: absolute;
            content: "";
            height: 18px;
            width: 18px;
            left: 0;
            bottom: 50%;
            margin-bottom: -9px;
            background-color: white;
            border: 1px solid $color-gray-dark;
            transition: .4s;
            border-radius: 50%;
        }
    }

    input {
        opacity: 0;
        width: 0;
        height: 0;

        &:checked + .slider {
            background-color: $color-green;
        }

        &:focus + .slider {
            box-shadow: 0 0 1px #2196F3;
        }
    }

    input:checked + .slider:before {
        transform: translateX(15px);
        border-color: $color-green;
    }

    &__name {
        margin-left: 10px;
        font-size: 14px;
        line-height: 16px;
    }
}

.switch-item {
    display: flex;
    align-items: center;
}



.search-info {
    margin-left: 20px;

    &__title {
        font-size: 14px;
        line-height: 16px;
        color: $color-gray-dark;
    }

    &__text {
        font-size: 14px;
        line-height: 16px;
        color: $color-blue-light;
    }
}

@media screen and (max-width: 1200px) {
    .search-info {
        &__title {
            font-size: 12px;
            line-height: 14px;
        }

        &__text {
            font-size: 12px;
            line-height: 14px;
        }
    }
}

@media screen and (max-width: 767px) {
    .search-info {
        margin-left: 0;
        margin-top: 15px;
        flex: 0 0 100%;
    }
}

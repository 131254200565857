@import "vars";
body {
    font-family: $font-roboto;
    color: $color-base;
    overflow-x: hidden;
}

.app {
    flex: 1;
    background: $color-gray;
    min-width: 100%;
    padding-left: 240px;
    min-height: 100%;
}

.page-content {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.green-text {
    color: $color-green !important;
}

.red-text {
    color: $color-red !important;
}

.item-title {
    color: $color-gray-dark;
    font-size: 14px;
    line-height: 16px;
}

.pagination-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.action-elem {
    border: 1px solid $color-blue-light;
    box-sizing: border-box;
    border-radius: 6px;
    cursor: pointer;
    color: $color-blue-light;
    transition: color .3s ease, background-color .3s;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;

    &:hover {
        color: $color-white;
        background-color: $color-blue-light;
    }

    &[data-action="archive"],
    &[data-action="delete"] {
        color: $color-red;
        border-color: $color-red;

        &:hover {
            background-color: $color-red;
            color: $color-white;
        }
    }

    &[data-action="active"] {
        color: $color-green;
        border-color: $color-green;

        &:hover {
            background-color: $color-green;
            color: $color-white;
        }
    }
}

.form {
    &__title {
        font-weight: bold;
        font-size: 22px;
        line-height: 26px;
        margin-bottom: 20px;
    }

    &__text {
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 20px;
    }

    .buttons-wrapper {
        display: flex;
        gap: 20px;
    }
}

.close-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 20px;
    top: 20px;
    width: 14px;
    height: 14px;
    cursor: pointer;

    span {
        display: block;
        position: absolute;
        width: 90%;
        height: 2px;
        background-color: $color-gray-dark;
        transform: rotate(45deg);
        transition: background-color .2s ease;

        &:last-child {
            transform: rotate(-45deg);
        };
    }

    &:hover {
        span {
            background-color: $color-base;
        }
    }
}

[data-action="copy"] {
    &.success__copy {
        color: $color-green !important;
        border-color: $color-green !important;

        &:hover {
            color: $color-green !important;
            border-color: $color-green !important;
            background-color: transparent !important;
        }
    }
}


.success-copy-tooltip {
    position: fixed;
    padding: 5px 10px;
    background-color: #2a2a2a;
    color: $color-white;
    font-size: 14px;
    z-index: 9999999;
}

.triangle-with-shadow {
    width: 30px;
    height: 30px;
    overflow: hidden;
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0 16px 10px -17px rgba(0,0,0,0.5);
}
.triangle-with-shadow:after {
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    background: $color-white;
    transform: rotate(45deg);
    top: 22px;
    left: 7px;
    box-shadow: -1px -1px 10px -2px rgba(0,0,0,0.5);
}

.table-wrapper {
    overflow-x: auto;
}

.pure-content-message {
    text-align: center;
    margin-top: 150px;

    img {
        margin: 0 auto 25px;
    }

    p {
        font-size: 22px;
        line-height: 26px;
        color: #B1BDD6;
        max-width: 330px;
        margin: 0 auto;
    }
}

.icon-info {
    width: 17px;
    height: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #8AA7E0;
    color: $color-white;
    border-radius: 50%;
    font-size: 12px !important;
}

.message-info {
    padding: 8px;
    background: #8AA7E0;
    box-shadow: 0 11px 26px rgba(128, 141, 162, 0.35);
    border-radius: 4px;
    position: absolute;
    top: 100%;
    left: 0;
    opacity: 0;
    pointer-events: none;
    transition: opacity .2s ease-in;
    color: $color-white;
    font-style: italic;
    font-size: 12px;
    line-height: 16px;
    z-index: 2;

    &._showed {
        opacity: 1;
    }
}

.available-news {
    &.js-hidden {
        display: none;
    }
}

@media screen and (max-width: 1200px) {
    .item-title {
        font-size: 12px;
        line-height: 14px;
    }
}

@media screen and (max-width: 992px) {
    .app {
        padding: 0;
    }
}

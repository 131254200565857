table:not(.table-condensed) {
    background: $color-white;
    font-size: 14px;
    line-height: 16px;
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
    box-shadow: 0 3px 8px rgba(0, 0, 0, .05);
    margin: 30px 0;
    thead {
        box-shadow: 0 1px 5px rgba(0, 0, 0, .06);
        border-bottom: 1px solid #f5f5f5;
    }
    th {
        padding: 13px 20px;
        text-align: start;
        font-weight: bold;
        cursor: pointer;
    }
    td {
        text-align: start;
        padding: 4px 20px;
    }
    td + td {
        border-left: 1px solid #f5f5f5;
    }
    tr + tr {
        border-top: 1px solid #f5f5f5;
    }
    th + th {
        border-left: 1px solid #f5f5f5;
    }
    tbody {
        tr:nth-child(odd) {
            background-color: rgba(138,167,224,.1);
        }
    }

    tr {
        cursor: pointer;
        transition: background-color .3s ease;
        &:hover {
            background-color: rgba(66,130,255,.1) !important;
        }
    }

    tfoot tr {
        font-weight: bold;
    }

    .sort-icons {
        display: flex;
        flex-direction: column;
        position: absolute;
        right: 0;
        top: 10px;
        .icon {
            line-height: 5px;
            height: 5px;
            cursor: pointer;
        }
        .icon + .icon {
            margin-top: 1px;
        }
        ._down {
            color: $color-red;
        }

        ._up {
            color: $color-green;
        }
    }

    .action-elem {
        width: 30px;
        height: 30px;
    }

    .empty-text {
        text-align: center;
        color: $color-gray-dark;
        padding: 40px 0;
        background-color: $color-white;
    }
}

@media screen and (max-width: 1200px) {
    table:not(.table-condensed) {
        width: 980px;
    }
}

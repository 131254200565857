.sidebar {
    background: $sidebar-bg;
    box-shadow: 2px 0 5px rgba(0, 0, 0, .45);
    flex: 0 0 240px;
    padding: 95px 20px 15px;
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 240px;
    top: 0;
    left: 0;
    height: 100%;
    overflow-y: auto;

    &__title {
        font-size: 12px;
        line-height: 14px;
        color: #637CAD;
        opacity: .7;
        margin-top: 30px;
    }

    &__list {
        margin-top: 10px;
    }

    &__navigation {
        margin-bottom: 20px;
    }

    &__link {
        display: block;
        font-size: 14px;
        line-height: 16px;
        color: #8AA7E0;
        padding: 12px 0;
        transition: color .3s ease;
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: -20px;
            width: 4px;
            height: 100%;
            border-radius: 2px;
            background: transparent;
        }

        &._active {
            color: $color-blue-light;
            font-weight: bold;
            &::before {
                background: $color-blue-light;
            }
        }

        &:hover {
            color: $color-blue-light;
        }

        .icon {
            margin-right: 10px;
        }
    }

    &__copyright {
        margin-top: auto;
        opacity: 0.7;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: #637CAD;
    }
}

@media screen and (max-width: 992px) {
    .sidebar {
        transform: translateX(-100%);
        transition: transform .5s ease;
        z-index: 998;

        &._active {
            transform: translateX(0);
        }
    }
}

.btn {
    display: block;
    padding: 14px 35px;
    border-radius: 30px;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;

    &-primary {
        background: $color-button-primary;
        color: $color-white;
        transition: color .2s ease, background-color .2s ease;

        &:active, &:hover {
            background: $color-button-primary-active;
        }
    }

    &-outlined {
        background: $color-button-outlined;
        color: $color-base;
        transition: color .2s ease, background-color .2s ease;
        border: 1px solid $color-base;

        &:active, &:hover {
            background: $color-button-outlined-active;
            color: $color-white;
        }
    }
}

@media screen and (max-width: 1200px) {
    .btn {
        font-size: 12px;
        line-height: 14px;
        padding: 10px 25px;
    }
}

.account-info {
    background: $color-white;
    border-radius: 8px;
    padding: 15px 0;

    &.small {
        flex: 1 1 auto;
    }

    &.large {
        flex: 0 0 518px;
        display: flex;
    }

    &.chart {
        flex: 1 1 30%;
        padding: 20px;
    }

    &.account-info__news {
        flex: 1 1 30%;

        .account-info__item--title {
            padding: 0 20px;
        }
    }

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
    }

    &__item {
        padding: 0 20px;
        flex: auto;

        &:not(:last-child) {
            border-right: 1px solid #ECEEF1;
        }

        &--title {
            font-weight: 500;
        }

        &--value {
            font-weight: bold;
            font-size: 24px;
            line-height: 28px;
            margin-top: 10px;
        }
    }
}

.account-info__wrapper + .account-info__wrapper {
    margin-top: 20px;
}

.chart {
    &__diagram {
        margin-top: 30px;
        svg {
            width: 80%;
            height: 80%;
            margin: 0 auto;
        }
    }

    &__text {
        color: $color-gray-dark;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: .7px;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 3;
    }

    &__title {
        font-weight: bold;
        font-size: 16px;
        line-height: 21px;
        margin-top: 15px;
    }

    &__list {
        margin-top: 30px;
        &--item {
            display: flex;
            align-items: center;
            &:not(:last-child) {
                margin-bottom: 10px;
            }

            .dot {
                flex: 0 0 16px;
                height: 16px;
                border-radius: 50%;
                margin-right: 5px;

                &.green {
                    background: $color-green;
                }

                &.yellow {
                    background: $color-yellow;
                }

                &.red {
                    background: $color-red;
                }

                &.blue {
                    background: $color-blue-light;
                }

                &.purple {
                    background: $color-purple;
                }
            }

            .name {
                font-size: 14px;
                line-height: 16px;
            }

            .value {
                margin-left: auto;
                font-size: 12px;
                line-height: 14px;
                color: #98A7B9;
            }
        }
    }
}

.account-info__news {
    overflow: hidden;
}

@media screen and (max-width: 1200px) {
    .account-info {
        &.large {
            flex: 0 0 100%;
        }

        &.small {
            flex: 1 0 48%;
        }

        &__item {
            &--value {
                font-size: 18px;
                line-height: 24px;
                margin-top: 5px;
            }
        }

        &.chart {
            flex: 1 0 48%;
        }
    }

    .chart {
        &__title {
            font-size: 14px;
            line-height: 16px;
        }
    }
}

@media screen and (max-width: 767px) {
    .account-info {
        &.small {
            flex: 0 0 100%;
        }

        &.chart {
            flex: 0 0 100%;
        }

        &__item {
            padding: 0 10px;
        }

        &.account-info__news {
            flex: 0 0 100%;
        }
    }
}

@media screen and (max-width: 360px) {
    .account-info {
        &.large {
            flex-wrap: wrap;
            gap: 10px;
        }

        &__item {
            flex: 0 0 100%;
        }
    }
}

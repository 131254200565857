.error {
    justify-content: center;
    align-items: center;

    h1 {
        font-size: 40px;
        line-height: 46px;
        z-index: 5;
    }

    p {
        font-size: 14px;
        line-height: 24px;
        z-index: 5;
    }

    .error__code {
        margin: 45px 0;
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 320px;
        line-height: 227px;
        color: #657D95;
        user-select: none;
        z-index: 5;
    }

    .illustration {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        width: 100%;

        img {
            max-width: 100%;
            margin: 0 auto;
        }
    }
}

@media screen and (max-width: 767px) {
    .error {
        h1 {
            font-size: 20px;
            line-height: 24px;
        }

        .error__code {
            font-size: 100px;
            line-height: 70px;
            height: 70px;

            img {
                height: 100%;
            }
        }

        p {
            max-width: 300px;
            margin: 0 auto;
        }
    }
}

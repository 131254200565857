.news {
    &__form {
        &--top, &--bottom {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }

        &--bottom {
            margin-top: 30px;
            > * {
                flex: 0 0 auto;
                margin-left: 20px;

                &:first-child {
                    margin-left: 0;
                }
            }

            .btn.btn-primary {
                flex: 0 0 205px;
            }
        }

        .fieldset {
            flex: 0 1 400px;
        }
    }

    &__table {

        th {
            padding: 13px 20px;
            text-align: start;
        }

        td {
            text-align: start;
            padding: 4px 20px;
        }

        &--image {
            width: 165px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &--h {
            width: 300px;
            text-align: start;
            padding: 0 20px;
        }

        &--mark {
            .category-mark {
                padding: 8px 10px;
                text-transform: uppercase;
                font-weight: bold;
                font-size: 12px;
                line-height: 14px;
                color: $color-white;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #D038FF;
                border-radius: 4px;
                width: 100px;
                height: 30px;
                margin: 0 auto;
            }
        }

        &--lang {
            img {
                margin: 0 auto;
            }
        }

        &--action {
            width: 115px;
            padding: 0 20px;

            button {
                width: 30px;
                height: 30px;
            }

            &-inner {
                display: flex;
                align-items: center;
                height: 100%;
                gap: 10px;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .news {
        &__form {
            &--bottom {
                > * {
                    margin-left: 10px;
                }

                .btn.btn-primary {
                    flex: 1;
                }

                .btn + .btn {
                    margin-left: 10px;
                }
            }
        }
    }
}

@media screen and (max-width: 576px) {
    .news {
        &__form {
            &--bottom {
                margin-top: 15px;

                > * {
                    margin-left: 0;
                    margin-top: 15px;
                }
            }
        }
    }
}

.pagination {
    border-radius: 20px;
    background: $color-white;
    display: flex;
    overflow: hidden;

    &__item {
        width: 40px;
        a {
            width: 100%;
            height: 100%;
            padding: 13px 0;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            line-height: 16px;
            transition: background-color .2s ease, color .2s ease;
            &:hover, &._active {
                background: $color-blue-light;
                color: $color-white;
            }
        }
    }

    &__item + &__item {
        border-left: 1px solid rgba(138,167,224, .2);
    }
}

@media screen and (max-width: 1200px){
    .pagination {
        &__item {
            width: 30px;

            a {
                padding: 8px 0;
                font-size: 12px;
                line-height: 14px;
            }
        }
    }
}

@media screen and (max-width: 767px){
    .pagination {
        flex: 0 1 auto;
        margin-top: 15px;
    }
}

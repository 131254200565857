$color-base:  #3F5171;
$color-gray: #F4F4F4;
$color-white: #FFFFFF;
$color-yellow: #FF9C31;
$color-red: #EE423D;
$color-green: #05C985;
$color-gray-dark: #808DA2;
$color-blue-light: #1F8EFA;
$color-purple: #843FA0;

//btn colors

$color-button-primary: #FF9C31;
$color-button-primary-focus: #FFAE56;
$color-button-primary-active: #FF9018;

$color-button-outlined: $color-white;
$color-button-outlined-focus: $color-base;
$color-button-outlined-active: #354561;

$sidebar-bg: linear-gradient(220.79deg, #37445F 1.98%, #1C2332 71.44%);

$font-roboto: 'Roboto', sans-serif;

.statistics {
    &__table {
        margin-top: 30px;

        .table-wrapper {
            overflow-x: auto;
        }

        table {
            width: 980px;
            th {
                font-weight: bold;
                padding: 13px 15px 13px 3px;
            }

            td {
                text-align: center;
                padding: 20px 4px;
            }
        }

        &--filters, &--buttons {
            display: flex;
            gap: 20px;
        }

        &--filters {
            flex-wrap: wrap;
        }

        &--actions {
            margin-top: 20px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
        }

        &--settings {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            &-link {
                display: flex;
                align-items: center;
                color: $color-blue-light;
                transition: color .2s ease;
                pointer-events: auto;
                &:hover {
                    color: $color-base;
                }
                .icon {
                    margin-right: 5px;
                }
            }
        }

        .settings-item + .settings-item {
            margin-left: 50px;
        }

        .setting-trigger {
            padding: 5px 12px;
            border-radius: 13px;
            &._opened {
                background: $color-white;
                .table-settings {
                    display: block !important;
                }
            }
        }
    }

    .table-settings {
        //position: absolute;
        //top: calc(100% + 13px);
        //left: 40px;
        //box-shadow: 0 1px 13px rgba(128, 141, 162, 0.36);
        //border-radius: 6px;
        //background: $color-white;
        //padding: 20px 0;
        //z-index: 5;
        //width: 215px;
        //height: 225px;
        //display: none;

        .settings-wrapper {
            //padding: 0 15px;
            //overflow-y: auto;
            height: 100%;
            //&::-webkit-scrollbar {
            //    width: 5px;
            //}
            //
            //&::-webkit-scrollbar-track {
            //    background: #f4f4f4;
            //}
            //
            //&::-webkit-scrollbar-thumb {
            //    background: $color-gray-dark;
            //    border-radius: 12px;
            //}
        }

        //input {
        //    width: 0;
        //    height: 0;
        //    position: absolute;
        //    top: 0;
        //    left: 0;
        //}
        //
        //label {
        //    width: 100%;
        //    height: 100%;
        //    display: flex;
        //    align-items: center;
        //    justify-content: space-between;
        //    font-weight: bold;
        //    color: $color-base;
        //    padding: 10px 0;
        //    border-bottom: 1px solid #EBEEF3;
        //    cursor: pointer;
        //    .check {
        //        border: 1px solid $color-gray-dark;
        //        box-sizing: border-box;
        //        border-radius: 6px;
        //        width: 20px;
        //        flex: 0 0 20px;
        //        height: 20px;
        //        display: flex;
        //        align-items: center;
        //        justify-content: center;
        //        .icon {
        //            margin: 0;
        //            color: $color-white;
        //            opacity: 0;
        //            &::before {
        //                line-height: 20px;
        //            }
        //        }
        //    }
        //}
        //
        //input:checked~label .check {
        //    background: $color-green;
        //    border-color: transparent;
        //    .icon {
        //        opacity: 1;
        //    }
        //}
    }
}

@media screen and (max-width: 1200px) {
    .statistics {
        .settings-item {
            font-size: 14px;
            line-height: 19px;
        }
    }
}

@media screen and (max-width: 767px) {
    .statistics {

        &__table {
            &--settings {
                margin-top: 20px;
                flex-direction: column;
                align-items: flex-start;
            }

            .table-settings {
                left: 0;
            }

            .settings-item {
                padding: 0;
            }

            .settings-item + .settings-item {
                margin-left: 0;
                margin-top: 15px;
            }
        }
    }
}

.domonetization {
    &__form {
        &--top {
            display: flex;
            gap: 30px;
        }

        &--textarea {
            margin-top: 30px;
        }

        &--bottom {
            display: flex;
            justify-content: flex-end;
            margin-top: 30px;
        }

        .filter {
            flex: 0 0 180px;
        }

        .textarea {
            height: 165px;
        }

        .btn {
            width: 205px;
        }
    }

    &__info {
        margin-top: 20px;
        font-size: 14px;
        line-height: 24px;

        &--title {
            font-weight: 500;
        }

        &--text {
            margin-top: 10px;
        }

        &--item {
            display: none;
        }

        &--item + &--item {
            margin-top: 30px;
        }
    }
}

@media screen and (max-width: 767px) {
    .domonetization {
        &__form {
            &--top {
                flex-wrap: wrap;
            }
        }
    }
}

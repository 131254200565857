.filter {
    border-radius: 8px;
    padding: 0 10px;
    background: $color-white;
    border: 1px solid $color-gray-dark;
    flex: 1 0 135px;
    cursor: pointer;
    * {
        user-select: none;
    }

    &.js-disabled {
        pointer-events: none;
        user-select: none;
    }

    &__item {
        cursor: pointer;
        font-size: 14px;
        line-height: 16px;
        &:not(:first-child) {
            border-top: 1px solid rgba(101,125,149,.15);
        }
        &.js-disabled {
            pointer-events: none;
            cursor: not-allowed;
            background-color: rgba(0,0,0,.05);

            label {
                pointer-events: none !important;
            }
        }

        &.not-data {
            font-size: 14px;
            line-height: 16px;
            opacity: 0.4;
            font-style: italic;
            pointer-events: none;
        }
    }
    &__header {
        overflow: hidden;
        padding: 15px 25px 15px 0;
        cursor: pointer;

        &--title {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            color: #C0C6D0;

            &.js-selected {
                margin: 0;
            }
        }

        .icon {
            position: absolute;
            right: -2px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    input.filter__item {
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    &._opened {
        border-color: $color-blue-light;

        .filter__header {
            .icon {
                transform: translateY(-50%) rotate(180deg);
            }
        }

        .filter__body {
            display: block;
            //flex-direction: column;
            //justify-content: space-between;
        }
    }

    &._opened + .label, &._selected + .label {
        top: -8px;
        left: 10px;
        opacity: 1;
        font-size: 12px;
        line-height: 14px;
        color: $color-blue-light;
    }

    &._selected + .label {
        color: $color-base;
    }

    &._selected {
        border: 1px solid #FF9C31;

        .filter__header--title {
            color: $color-base;
        }
    }
}

.form__filter--body {
    position: absolute;
    top: calc(100% + 1px);
    left: -1px;
    width: calc(100% + 2px);
    background: $color-white;
    display: none;
    box-shadow: 0 1px 13px rgba(128, 141, 162, 0.358);
    border-radius: 8px;
    z-index: 5;
    overflow-x: auto;
    max-height: 200px;

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        background: #f4f4f4;
    }

    &::-webkit-scrollbar-thumb {
        background: $color-gray-dark;
        border-radius: 12px;
    }

    input {
        position: absolute;
        width: 0;
        height: 0;
        top: 0;
        left: 0;
    }

    label {
        display: block;
        width: 100%;
        padding: 15px 10px;
        cursor: pointer;
        z-index: 2;
        transition: background-color .2s ease;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        &:hover {
            background-color: $color-blue-light;
            color: $color-white;
        }
    }

    input:checked + label {
        color: $color-white;
        font-weight: bold;
        background-color: $color-blue-light;
    }
}

@media screen and (max-width: 1200px) {
    .filter {
        &__item {
            font-size: 12px;
            line-height: 14px;
        }

        &__header {
            padding: 10px 25px 10px 0;
            font-size: 12px;
            line-height: 14px;
        }
    }
}

@media screen and (max-width: 576px) {
    .filter {
        flex: 0 0 100%;
    }
}

.list__tabs {
    display: flex;
    border-bottom: 1px solid rgba(101,125,149,.15);

    &--item {
        flex: 0 0 auto;
    }

    &--link {
        display: block;
        width: 100%;
        height: 100%;
        font-weight: bold;
        font-size: 20px;
        line-height: 23px;
        color: $color-gray-dark;
        padding: 0 20px 11px;
        border-bottom: 1px solid transparent;
        transition: color .2s ease, background-color .2s ease;

        &._active {
            border-color: $color-yellow;
            color: $color-base;
        }

        &:hover {
            color: $color-base;
        }
    }
}

@media screen and (max-width: 1200px) {
    .list__tabs {
        &--link {
            font-size: 16px;
            line-height: 19px;
            padding: 0 15px 8px;
        }
    }
}

@media screen and (max-width: 360px) {
    .list__tabs {
        &--link {
            font-size: 14px;
            line-height: 16px;
            padding: 0 10px 8px;
        }
    }
}

.link {
    font-size: 14px;
    line-height: 16px;
    color: $color-blue-light;
    transition: opacity .2s ease;

    &:hover {
        opacity: .7;
    }
}

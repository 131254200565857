.content {
    flex: 1;
    padding: 20px;

    &__inner {
        max-width: 980px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        height: 100%;
    }
}

@media screen and (max-width: 1200px){
    .content {
        &__inner {
            max-width: 100%;
        }
    }
}

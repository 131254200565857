.finances {
    &__top {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .support-text {
            font-size: 14px;
            line-height: 24px;
            flex: 1;
            padding-left: 20px;
        }
    }

    &__systems {
        margin-top: 30px;

        &--title {
            font-weight: bold;
            font-size: 20px;
            line-height: 23px;
        }

        &--body {
            display: flex;
            gap: 20px;
            margin-top: 20px;
            flex-wrap: wrap;
        }

        &--item {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 0 1 180px;
            background-color: $color-white;
            height: 100px;
            border-radius: 8px;
            padding: 25px;
            transition: box-shadow .3s ease;

            &:hover {
                box-shadow: 0 0 4px rgba(128, 141, 162, 0.5);
            }

            img, picture {
                max-width: 100%;
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }

    &__table {
        &--sum {
            font-weight: bold;
        }

        table {
            td.finances__table--sum {
                text-align: center;
            }
            td {
                padding: 20px;
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .finances {
        &__top {
            .support-text {
                font-size: 12px;
                line-height: 14px;
                flex: 1;
                padding-left: 0;
                margin-top: 20px;
            }
        }

        &__systems {
            &--item {
                flex: 0 0 30%;
                padding: 10px;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .finances {
        &__systems {
            &--item {
                flex: 0 1 45%;
            }
        }
    }
}

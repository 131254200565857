/* custom-reset.css | 27.08.2018 | https://yurch-html.github.io/dist/custom-reset.html */

*,
*:before,
*:after {
    box-sizing: border-box;
    position: relative;
}

html {
    height: 100%;
}

body {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    margin: 0;
    background-color: #fff;
    line-height: 1;
    text-rendering: optimizeLegibility;
    text-decoration-skip: objects;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
}

:focus {
    outline: none;
}

p,
dd,
dl,
figure,
blockquote {
    margin: 0;
}

blockquote,
q {
    quotes: none;
}

ul,
ol {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

th {
    font-weight: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    font-size: inherit;
    font-weight: inherit;
}

audio,
video {
    display: block;
}

img {
    display: block;
    border: none;
}

iframe {
    border: none;
}

pre,
code,
kbd,
samp {
    font-family: monospace, monospace;
    font-size: inherit;
}

a {
    background-color: transparent;
    text-decoration: none;
    color: inherit;
}

abbr {
    border: none;
    text-decoration: none;
}

//b,
//strong {
//    font-weight: inherit;
//}

i,
em {
    font-style: inherit;
}

dfn {
    font-style: inherit;
}

mark {
    background-color: transparent;
    color: inherit;
}

small {
    font-size: inherit;
}

sub,
sup {
    position: relative;
    vertical-align: baseline;
    font-size: inherit;
    line-height: 0;
}

sub {
    bottom: -.25em;
}

sup {
    top: -.5em;
}

button,
input,
optgroup,
select,
textarea {
    padding: 0;
    margin: 0;
    border: none;
    border-radius: 0;
    box-shadow: none;
    background-color: transparent;
    font: inherit;
    color: inherit;
    letter-spacing: inherit;
}

button,
input {
    overflow: visible;
}

button,
select {
    text-align: left;
    text-transform: none;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
    cursor: pointer;
    -webkit-appearance: none;
}

textarea {
    resize: none;
    overflow-y: auto;
    overflow-x: hidden;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
    border: none;
    padding: 0;
}

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
    outline: none;
}

[type='number'] {
	-moz-appearance: textfield;
}
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
    height: auto;
	-webkit-appearance: none;
    margin: 0;
}

[type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
}

[type='search'] {
    outline: none;
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
}

fieldset {
    padding: 0;
    margin: 0;
    border: none;
}

legend {
    display: block;
    padding: 0;
    white-space: normal;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

select::-ms-expand {
    display: none;
}

::placeholder {
    color: inherit;
    opacity: 1;
    transition: opacity .3s;
}

::-webkit-input-placeholder {
    color: inherit;
    opacity: 1;
    transition: opacity .3s;
}

:-moz-placeholder {
    color: inherit;
    opacity: 1;
    transition: opacity .3s;
}

:-ms-input-placeholder {
    color: inherit;
    opacity: 1;
    transition: opacity .3s;
}

:focus::placeholder {
    opacity: 0;
}

:focus::-webkit-input-placeholder {
    opacity: 0;
}

:focus::-moz-placeholder {
    opacity: 0;
}

:focus:-ms-input-placeholder {
    opacity: 0;
}

svg {
    display: block;
    width: 100%;
    height: 100%;
    fill: currentColor;
}

[hidden] {
    display: none;
}

:disabled,
.disabled {
    cursor: not-allowed;
}

::-ms-clear {
    display: none;
}

:-webkit-autofill {
    box-shadow: 0 0 100px #fff inset;
    -webkit-text-fill-color: currentColor;
}

::selection {
    color: #fff;
    background-color: #1D0D0B;
}

.clearfix:after {
    content: '';
    display: block;
    clear: both;
}

.visually-hidden {
    position: absolute;
    z-index: -1;
    width: 0;
    height: 0;
    padding: 0;
    margin: 0;
    border: none;
    overflow: hidden;
}

.cover-pic,
.contain-pic {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.cover-pic {
    object-fit: cover;
}

.contain-pic {
    object-fit: contain;
}

.footer {
    margin-top: auto;
}

@import "custom-reset";

@import "vars";


@import "general";

@import "vendor/jquery.fancybox.css";
@import "vendor/daterangepicker.css";

@import "fonts";
@import "components/sidebar";
@import "components/header";
@import "components/dropdown-item";
@import "blocks/content";
@import "components/calendar";
@import "components/filter";
@import "components/input";
@import "components/table";
@import "components/btn";
@import "components/link";
@import "components/top-news-item";
@import "components/switch";
@import "components/tabs";
@import "blocks/search-info";
@import "blocks/render-count";
@import "blocks/pagination";
@import "blocks/popup";
@import "blocks/account-info";
@import "blocks/statistcs";
@import "blocks/news";
@import "blocks/faq";
@import "blocks/streams";
@import "blocks/domonetization";
@import "blocks/parking";
@import "blocks/finances";
@import "blocks/profile";
@import "blocks/auth";
@import "blocks/not_found";
@import "blocks/success_cash";

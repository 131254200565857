.streams {

    &__content {
        margin-top: 30px;

        &--inner {
            display: none;

            &._active {
                display: block;
            }
        }
    }

    &__form {
        display: flex;
        align-items: center;

        > * {
            margin-left: 20px;

            &:first-child {
                margin-left: 0;
            }
        }

        .input-search {
            flex: 0 0 265px;
        }
    }

    &__table {

        &--date {
            width: 135px;
        }
        &--name {
            width: 180px;
            text-align: start;
        }
        &--image {
            width: 175px;
        }
        &--h {
            width: 300px;
            text-align: start;
        }
        &--action {
            padding: 0 15px !important;

            .action-elem {
                width: 30px;
                height: 30px;
            }

            .actions {
                display: flex;
                flex-wrap: wrap;
                gap: 10px;
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .streams {
        &__form {
            flex-wrap: wrap;
        }

        .btn {
            margin-left: 0;
            margin-top: 20px;
        }
    }
}

@media screen and (max-width: 767px) {
    .streams {
        &__form {
            .input-search {
                margin-left: 0;
                flex: 1 1 auto;
            }
        }

        .btn {
            margin-left: 0;
        }
    }
}

@media screen and (max-width: 576px) {
    .streams {
        &__form {
            .input-search {
                margin-top: 15px;
                flex: 0 0 100%;
            }
        }
    }
}

.fieldset {
    //overflow: hidden;
}

input.input {
    background: $color-white;
    border: 1px solid $color-gray-dark;
    border-radius: 8px;
    padding: 15px 10px 15px 0;
    width: 100%;
    cursor: pointer;
    font-size: 14px;
    line-height: 16px;
}

label.label {
    pointer-events: none;
    position: absolute;
    top: 15px;
    left: 1px;
    color: $color-gray-dark;
    opacity: .5;
    background: $color-white;
    transition: top .3s ease, font-size .3s ease;
    padding: 3px 10px;
    font-size: 14px;
    line-height: 16px;
    border-radius: 13px;
    z-index: 2;

    span {
        color: $color-red;
    }
}

input.input._focus + label.label, input.input._valid + label.label {
    top: -8px;
    left: 10px;
    opacity: 1;
    font-size: 12px;
    line-height: 14px;
    color: $color-blue-light;
}

input.input._focus + label.label + .icon, input.input._valid + label.label + .icon {
    opacity: 1;
    color: $color-blue-light;
}

input.input._focus, input.input._focusout {
    padding: 15px 10px;
}

input.input._focus {
    border-color: $color-blue-light;
}

input.input._focusout + label.label {
    color: $color-base;
}

input.input._focus:focus {
    border-color: $color-blue-light;
}

textarea.textarea {
    background: $color-white;
    border: 1px solid $color-gray-dark;
    border-radius: 8px;
    padding: 15px 10px;
    resize: vertical;
    width: 100%;

    &:focus, &:active {
        opacity: 1;
        border-color: $color-blue-light;
    }
}

.input-search {
    .icon {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        opacity: .5;
        z-index: 2;
        font-size: 20px;
    }
    label {
        left: 35px;
    }
    input {
        padding-left: 40px !important;
    }
}

.fieldset{

    .error-text {
        display: none;
        position: absolute;
        left: 0;
        top: calc(100% + 2px);
        font-size: 12px;
        line-height: 14px;
        color: $color-red;
    }

    &._error {
        input.input {
            border-color: $color-red;
        }
        label.label {
            color: $color-red !important;
        }

        .error-text {
            display: block;
        }
    }
}

@media screen and (max-width: 1200px) {
    input.input {
        font-size: 12px;
        line-height: 14px;
        padding: 10px 10px 10px 0;
    }

    label.label {
        font-size: 12px;
        line-height: 14px;
        top: 9px;
    }

    input.input._focus, input.input._focusout {
        padding: 10px;
    }

    .input-search {
        .icon {
            font-size: 13px;
        }
    }
}

@media screen and (max-width: 767px) {
    input.input._focus + label.label, input.input._valid + label.label {
        font-size: 8px;
    }
}
